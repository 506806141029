/* eslint-disable import/extensions */
/* eslint-disable prettier/prettier */
import React from "react";
import Logo from "@/components/header/components/logo";
import Searcher from "@/components/header/components/searcher-react";
import Menu from "@/components/header/components/menu";
import UserPill from "@/components/header/components/user-pill";

const Header1Main = (props) => {
    const {
        logged,
        userName,
        mainOptions,
        segmentMenu,
        burgerSections,
        burgerOptions,
        accountSections,
    } = props;
    const selector = logged ? "logged" : "default";

    return (
        <div className="header1-main container">
            <div className="menu">
                <Menu
                    layout="burguer"
                    items={burgerSections[selector]}
                    segmentMenu={segmentMenu[selector]}
                    options={burgerOptions[selector]}
                    account={accountSections}
                />
            </div>
            <div className="logo">
                <Logo />
            </div>
            <div className="searcher">
                <Searcher needOpenModalResults />
            </div>
            <div className="options">
                <Menu items={mainOptions[selector]} layout="icons" location="all"/>
            </div>
            {logged && <UserPill userName={userName} userType={logged} items={accountSections} />}
        </div>
    );
};
export default Header1Main;
