import React, { Suspense, useEffect, useState } from "react";
import Header from "@/components/header";
import { useDispatch, useSelector } from "react-redux";
import { actions as headerActions } from "@/reducers/header-info-reducer";
import { actions as modalActions } from "@gerenciatd/next13.reducers.modals-reducer";
import { actions as mainActions } from "@gerenciatd/next13.reducers.main-reducer";
import { mustBeShownHeader, mustBeShownHeaderLight } from "./utils/utils";
import { persistedInfo } from "./utils/persistedInfo";
import HeaderLight from "./components/headerLight";
import { createBrowserHistory } from "history";

function App() {
  const Modal = useSelector(state => state.modals.Modal);
  const HeaderInfo = useSelector(state => state.headerInfo);
  const contextInfo = useSelector(state => state.main.context);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(headerActions.startLoadInfo(window.location));
    // analizamos persistido y lanzamos en consecuencia
    const persisted = persistedInfo();
    if (persisted?.geo) {
      dispatch(mainActions.setCpParams(persisted.geo));
    } else {
      dispatch(mainActions.checkCPAndSetZones({}));
    }

    // definimos las subscripciones, algo en vcm corta la forma buena de hacerlo
    const history = createBrowserHistory();
    history.listen(() => {
      dispatch(headerActions.startNookInfoHeader({ typeNook: 'refresh' }));
    });
    window.nookInfoExchange.registerAsObserver('HeaderEnvironment', `Headerenvironment-${Date.now()}`, (info) => {
      if (info) {
        dispatch(headerActions.startNookInfoHeader({ info, typeNook: 'headerEnvironment' }));
      }
    });
    window.nookInfoExchange.registerAsObserver('Context', `Headercontext-${Date.now()}`, (info) => {
      if (info) {
        dispatch(headerActions.startNookInfoHeader({ info, typeNook: 'context' }));
      }
    });
    window.nookInfoExchange.registerAsObserver('Location-neo', 'HeaderRefresh', (info) => {
      if (info) {
        dispatch(headerActions.startNookInfoHeader({ typeNook: 'refresh' }));
      }
    });



  }, []);

  const mustBeShown = mustBeShownHeader(HeaderInfo);
  const mustBeShownLight = mustBeShownHeaderLight(HeaderInfo);

  if (mustBeShown) {
    if (mustBeShownLight) {
      return <div className="App">
        {HeaderInfo &&
          <HeaderLight {...HeaderInfo} />
        }
      </div>
    }
    return (
        <div id="header-app-rr" className="App">
          {HeaderInfo && HeaderInfo.mainOptions &&
            <Header {...HeaderInfo} context={contextInfo} />
          }
          <Suspense fallback={<div></div>}>
            {Modal && <Modal
              addModal={(data) => {
                dispatch(modalActions.addModal(data));
              }} />}
          </Suspense>
        </div>
    );

  } else {
    return null
  }
}

export default App;