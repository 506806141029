import React, { useState } from "react";
import "@/styles/components/close-button.scss";

const CloseButton = ({ color, backgroundColor, onHoverColor, onClick }) => {
  const [isOnHover, setIsOnHover] = useState(false);
  return (
    <>
      <div className="close-container"
        onMouseEnter={() => setIsOnHover(true)}
        onMouseLeave={() => setIsOnHover(false)}
        onClick={onClick}
        style={{ backgroundColor: isOnHover ? onHoverColor : backgroundColor }}>
        <div className="cros">
          <span className="one"></span>
          <span className="two" style={{ backgroundColor: color }}></span>
        </div>
      </div>
    </>
  );
};

export default CloseButton;
