/* eslint-disable no-plusplus */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { 
  generateOnHandlerMethods,
  extendsAccesibility,
} from './utils'

const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;

const appendHost = (href) => {
  if (href.indexOf('http') === -1 && href.indexOf('//') === -1) {
    switch(currentEnv) {
      case 'dev': href = `http://127.0.0.1:8089${href}`; break;
      case 'prod': href = `https://www.movistar.es${href}`; break;
      case 'qa': href = `https://qa.cms.col.movistar.es${href}`; break;
    }
  }
  return href;
}


const checkIfHasHrefOperators = (href) => {
  let result = false;
  const OPERATORS = ['tel:', 'mailto:'];
  OPERATORS.forEach(item => {
    if (!result && href.indexOf(item) > -1) {
      result = true;
    }
  })
  return result;
}


/**
 * @name CustomLinkReact
 * @description atomic component
 * @see {@link https://nextjs.org/docs/api-reference/next/link}
 * @param {LinkProps} props
 * @param {React.Ref} ref
 * @returns {React.FC} 
 */

const CustomLinkReact = ({
  id,
  children,
  className,
  internal,
  href,
  taggingInfo,
  title: cmpTitle,
  target,
  ariaLabel: cmpAriaLabel,
  screenReadable: cmpScreenRedable,
  style,
  onClick,
  buttonParent,
  buttonTypeHtml,
  disabled,
  custoObjectInfo,
  cyData,
}) => {

  let currentClassName = `next-link${className ? ` ${className}` : ''}`;
  const finalCssName = !children
    ? `${currentClassName} nextLinkOverBox`
    : currentClassName;

  const { title, ariaLabel, ariaHidden } = extendsAccesibility({
    cmpTitle,
    cmpAriaLabel,
    cmpScreenRedable,
    taggingInfo,
  });
  /* wrapeamos funciones ejecutadoras */
  const {
    newOnClick: onClickHandler,
    newOnKeyDown: onKeyDownHandler,
    newOnMouseDown: onMouseDownHandler,
  } = generateOnHandlerMethods({
    href,
    onClick,
    taggingInfo,
    disabled,
  });


  // personalizo
  let editModal = null;
  if (custoObjectInfo) {
    editModal = () => {
      custoObjectInfo.handler(custoObjectInfo.entryPoint);
    }
    currentClassName = `${currentClassName} custo-editable`
    if (children) {
      return(
        <div className={currentClassName} onClick={editModal}>
          {children}
        </div>
      )
    }
    return (
      <div className={currentClassName} onClick={editModal}>Enlace</div>
    )
  }
  
  // 0) vemos si tiene href sino tiene solo es un elemento para taggear (TODO)
  if (!href && onClick) {
    return (
      <button
        id={id || null}
        type={buttonTypeHtml}
        className={finalCssName}
        tabIndex={0}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        title={title}
        onClick={onClickHandler}
        onKeyDown={onKeyDownHandler}
        onMouseDown={onMouseDownHandler}
        disabled={disabled}
        style={style}
        data-cy={cyData}
      >
        {buttonParent && ( <div className="hover-layer" /> )}
        {children}
      </button>
    );
  }

  href = appendHost(href)
  // "2 ) logica de link as usual"

  return (
    <a
      id={id || null}
      className={finalCssName}
      tabIndex={0}
      aria-label={ariaLabel}
      aria-hidden={ariaHidden}
      title={title}
      target={target}
      href={href}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      onMouseDown={onMouseDownHandler}
      disabled={disabled}
      style={style}
      data-cy={cyData}
    >
      {buttonParent && ( <div className="hover-layer" /> )}
      {children}
    </a>
  );
};

CustomLinkReact.propTypes = {
  /**
   * Id del componente.
   */
  id: PropTypes.string,
  /**
   * Enlace al que se quiere redireccionar.
   */
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Función que se quiere ejecutar al clickar el componente.
   */
  onClick: PropTypes.func,
  /**
   * Clase para estilos adicionales.
   */
  className: PropTypes.string,
  /**
   * Información para el tagueado del componente.
   */
  taggingInfo: PropTypes.object.isRequired,
  /**
   * Comportamiento para cuando se utiliza href, por defecto
   * es _self pero se puede cambiar a _blank si se quiere que
   * el enlace se abra en una nueva pestaña.
   */
  target: PropTypes.string,
  /**
   * Texto que se muestra al pasar el ratón por encima del componente.
   *  Primero utiliza taggingInfo.componentContent y si es nulo utiliza este atributo.
   */
  title: PropTypes.string,
  /**
   * Proporciona una descripción
   * precisa y concisa del contenido del elemento para usuarios de
   * tecnologías de asistencia, como lectores de pantalla, que
   * no pueden ver o interpretar el contenido visual.
   * Primero utiliza taggingInfo.componentContent y si es nulo utiliza este atributo.
   */
  ariaLabel: PropTypes.string,
  /**
   * Deshabilita el componente (solo en ocasiones que se utiliza onClick).
   */
  disabled: PropTypes.bool,
  /**
   *  Cuando se establece en true, indica que el elemento no debe ser presentado visualmente o leído por el lector de pantalla.
   */
  screenReadable: PropTypes.bool,
  /**
   * Distintas propiedades para enlaces en relativo que se consideren internos a la app.
   */
  CustomLinkReactProps: PropTypes.shape({
    passHref: PropTypes.bool,
    replace: PropTypes.bool,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    scroll: PropTypes.bool,
    shallow: PropTypes.bool,
    locale: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }),
  /**
   * Etstablece el tipo de elemento cuando usamos onClick.
   */
  buttonTypeHtml: PropTypes.string,
  buttonParent: PropTypes.bool,
  style: PropTypes.object,
  cyData: PropTypes.string,
  internal: PropTypes.bool,
};

CustomLinkReact.defaultProps = {
  id: null,
  className: "",
  target: "_self",
  screenReadable: true,
  disabled: false,
  onClick: null,
  buttonTypeHtml: "button",
  buttonParent: false,
  style: {},
  cyData: '',
  internal: false,
};

export default CustomLinkReact;
