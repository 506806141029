/* eslint-disable prettier/prettier */
import React from "react";
import Breadcrumb from "@/components/header/components/breadcrumb";

const Header3Breadcrumb = (props) => {
  const { breadcrumb } = props;
  if (breadcrumb) {
    return (
      <div className="header3-breadcrumb container">
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
    );
  }
  return null;
};
export default Header3Breadcrumb;
