
export const types = {
    /* LOAD INFO */
    LOAD_INFO: 'LOAD_INFO',
    SET_LOAD_INFO: 'SET_LOAD_INFO',
    /* SUBSCRIPTION */
    START_NOOK_INFO_DATA: 'START_NOOK_INFO_DATA',
    SET_NOOK_INFO_DATA: 'SET_NOOK_INFO_DATA',
    /* POSTAL CODE */
    CHECK_CP_AND_SET_ZONES: 'CHECK_CP_AND_SET_ZONES',
    SET_CHECKED_CP_BY_API: 'SET_CHECKED_CP_BY_API',
    SET_CP_INFO: 'SET_CP_INFO',
}

export const actions = {
    startLoadInfo: (location) => ({ type: types.LOAD_INFO, location }),
    setLoadInfo: obj => ({ type: types.SET_LOAD_INFO, obj }),
    startNookInfoHeader: (data) => ({ type: types.START_NOOK_INFO_DATA, data }),
    setNookInfoHeader: nookInfo => ({ type: types.SET_NOOK_INFO_DATA, nookInfo }),
}


export const initialState = {
    cp: "...",
    zone: "",
    // municipio seleccionado, por cliente
    municipio: "",
    // lista de posibles municipios bajo ese cp
    municipiosPosibles: null,
    checkedCpByApi: true,
};

const headerInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LOAD_INFO:
            const { obj } = action;
            const newState = obj;
            return { ...state, ...newState };

        case types.SET_NOOK_INFO_DATA:
            const { nookInfo } = action;
            return {
                ...state,
                ...nookInfo,
            };

        default:
            return { ...state }
    }
}

export default headerInfoReducer;
