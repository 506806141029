import React, { Component } from "react";
import PropTypes from "prop-types";

export const STICKYTYPES = {
  DYNAMICTOP: "dynamicTop",
  DYNAMICBOTTOM: "dynamicBottom",
  STATICTOP: "staticTop",
  STATICBOTTOM: "staticBottom",
};

// const ACCURACY = 5;
const MOUNT_RETRIES = 0;
const MAX_MOUNT_RETRIES = 10;

// genera la clase que controla
const generateStickyClassName = ({ type, className, fixed }) => {
  //console.log('[Sticky] generamos CssName: ', { type, fixed });
  let stickyClass = `Sticky ${className}`;

  switch (type) {
    case STICKYTYPES.DYNAMICTOP:
      stickyClass = `${stickyClass} dynamicTop`;
      if (fixed) {
        stickyClass = `${stickyClass} fixed-sticky`;
      }
      break;
    case STICKYTYPES.DYNAMICBOTTOM:
      stickyClass = `${stickyClass} dynamicBottom`;
      if (fixed) {
        stickyClass = `${stickyClass} fixed-sticky`;
      }
      break;
    case STICKYTYPES.STATICTOP:
      stickyClass = `${stickyClass} staticTop`;
      break;
    case STICKYTYPES.STATICBOTTOM:
      stickyClass = `${stickyClass} staticBottom`;
      break;
    default:
      console.warn(
        "Type is not defined into STICKYTYPES enum, please use one value from that ENUM"
      );
      return null;
  }
  // console.log('[Sticky]: Nuevo render con: ', stickyClass);
  return stickyClass;
};

// aplica paddings al border
const applyDocumentPadding = ({ type, fixed, height }) => {
  try {
    switch (type) {
      case STICKYTYPES.DYNAMICTOP:
        if (fixed) {
          document.body.style.paddingTop = `${height}px`;
        } else {
          document.body.style.paddingTop = 0;
        }
        break;
      case STICKYTYPES.DYNAMICBOTTOM:
        if (fixed) {
          document.body.style.paddingBottom = `${height}px`;
        } else {
          document.body.style.paddingBottom = 0;
        }
        break;
      default:
        break;
    }
  } catch (error) {
    // seguramente estes en server
    // console.error(error);
  }
};

class Sticky extends Component {
  constructor() {
    super();
    this.state = {
      fixed: false,
    };
  }

  componentDidMount() {
    const { type } = this.props;

    const interval = setInterval(() => {
      const el = document.querySelector(".Sticky");
      let heightAux = 0;
      if (el) {
        heightAux = el.getBoundingClientRect().bottom || el.offsetHeight;
        if (
          type !== STICKYTYPES.STATICBOTTOM &&
          type !== STICKYTYPES.STATICTOP
        ) {
          this.height = heightAux;
        } else if (type === STICKYTYPES.STATICTOP) {
          document.body.style.paddingTop = `${this.height}px`;
        } else if (type === STICKYTYPES.STATICBOTTOM) {
          document.body.style.paddingBottom = `${this.height}px`;
        }
      }
      if (MOUNT_RETRIES >= MAX_MOUNT_RETRIES || this.height) {
        clearInterval(interval);
        this.handleScroll();
        window.addEventListener("scroll", this.handleScroll, { passive: true });
      }
    }, 10);

    window.scrollTo(0, 0);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("scroll", this.handleScroll);
    } catch (error) {
      // console.error(error);
    }
  }

  handleScroll = () => {
    const { type } = this.props;
    const { fixed } = this.state;
    let landing = false;
    const currentScrollY = parseInt(window.scrollY, 10);
    // const diff = Math.abs(currentScrollY - lastScrollY);
    // const accuracyFlag = diff > ACCURACY || currentScrollY <= 0;

    const belowHeightCnd = currentScrollY >= this.height;
    const aboveHeightCnd = currentScrollY <= this.height;
    let newFixed = fixed;
    if (!fixed && belowHeightCnd) {
      newFixed = true;
    }

    if (fixed && aboveHeightCnd) {
      newFixed = false;
      landing = true;
    }

    const newFixedDiferentThanLast = newFixed !== fixed;

    if (newFixedDiferentThanLast) {
      this.setState({
        fixed: newFixed,
        landing,
        height: this.height,
      });

      const appElement = document.getElementById('header-root-plan-r');
      const referenceElement = document.getElementById('header-app-rr')
      if (referenceElement && referenceElement.offsetHeight){
          appElement.style.minHeight='auto';
      }
      
    }
  };

  render() {
    const { children, type, className } = this.props;
    const { fixed, landing, height } = this.state;
    const stickyClass = generateStickyClassName({ type, className, fixed });
    applyDocumentPadding({ type, fixed, height });
    return <div className={`${stickyClass}${landing || ""}`}>{children}</div>;
  }
}

Sticky.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
};

Sticky.defaultProps = {
  type: STICKYTYPES.DYNAMICTOP,
  className: "",
};

export default Sticky;
