import React from "react";
import CustomLink from '@/components/custom-link-react';
import MovistarLogo from "@gerenciatd/next13.glyph.movistar-m";

export const Logo = (props) => {
  return (

    <CustomLink
      href="https://www.movistar.es"
      title="Movistar"
      ariaLabel="Ir a la home"
      taggingInfo={{
        componentName: "header",
        componentContent: "LogoM",
        componentVariation: "all",
        componentPerso: "",
        componentProduct: "",
      }}
      data-cy="Header Movistar logo"
    >
      <MovistarLogo title="Movistar" />
    </CustomLink>

  );
};

export default Logo;
