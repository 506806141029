/* eslint-disable prettier/prettier */
import React from "react";
import Menu from "@/components/header/components/menu";

const Header2Nav = (props) => {
  const { logged, cp, navSections, segmentMenu, municipiosPosibles, municipio } = props;
  const selector = logged ? "logged" : "default";

  return (
    <div className="header2-nav container">
      <div className="menu">
        <div className="section-menu" data-cy="Header links">
          <Menu items={navSections[selector]} layout="default" location="menu"/>
        </div>
        <div className="segment-menu" data-cy="Header secondary links">
          <Menu items={segmentMenu[selector]} layout="default" location="menu"/>
        </div>
      </div>
      <div className="options">
        <Menu layout="cp" location="menu" cp={cp} municipiosPosibles={municipiosPosibles} municipio={municipio} />
      </div>
    </div>
  );
};
export default Header2Nav;
