/* eslint-disable prettier/prettier */
import React from "react";
import Button from "@/components/custom-link-react";
import Text from "@gerenciatd/next13.components.text";
import '@/components/custom-link-react/style.scss';
const Header4Section = (props) => {
  const items = props["pills"] ? Object.values(props["pills"]) : null;
  if (!items) {
    return null;
  }
  let i = 0;
  return (
    <div className="header4-section">
      <div className="container">
        <div className="pill-section" data-cy="Header pills">
          {items.map((item) => {
            const link = item.link ? item.link : "#";
            const text = item.text;
            const active = item.selected;
            const target = item.target_link ? item.target_link : "_self";
            return (
              <div key={`item-${i++}`} className="pill-item">
                <Button
                  className={`buttonx ${active ? " active" : ""}`}
                  // onClick={() => {
                  //   window.location.href = link;
                  // }}
                  href={link || null}
                  label={text}
                  target={target}
                  type={active ? "tab_active" : "tab"}
                  height="normal"
                  width="medium"
                  taggingInfo={{
                    componentName: "header",
                    componentContent: text,
                    componentVariation: "tab",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                >
                  <Text
                    text={text}
                    fontType="font-regular"
                    size="caption05"
                    color={active ? "grey6" : "grey5"}
                    display="inline"
                  />
                </Button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Header4Section;
