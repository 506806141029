import sendGaEvent from '@gerenciatd/next13.utils.metrics.send-ga-component-event';

const ENTER_KEY = "enter";
const RIGHT_MOUSE_BUTTON = 2;

const properKeyChecker = (e) =>
  e && e.code && e.code.toLowerCase() === ENTER_KEY;

const properMouseButtonChecker = (e) =>
  (e && e.which && e.which !== RIGHT_MOUSE_BUTTON) ||
  (e && e.button && e.button !== RIGHT_MOUSE_BUTTON);

const wrapperFunction = ({
  action2Execute = null,
  taggingInfo,
}) => {
  try {
    const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;
    const isFrontApp = typeof window !== "undefined";
    // si estoy en cliente (window) y estoy en dev obligo al parametro
    const isMetricsDevEnable =
      isFrontApp && window.location.search.indexOf("metrics") > -1;
    if ((isFrontApp && currentEnv !== "dev") || isMetricsDevEnable) {
      sendGaEvent(taggingInfo);
    }
    if (action2Execute) {
      action2Execute();
    }
  } catch (error) {
    let info = "";
    try {
      info = `Element taggingInfo: ${JSON.stringify(taggingInfo)}`;
    } catch (e) {
      console.error("No se pudo generar más info en base al taggingInfo");
    }
    console.error({ action2Execute });
    throw error;
  }
};

export const generateOnHandlerMethods = ({
  onClick,
  taggingInfo,
  disabled,
}) => {
  let newOnClick = null;
  let newOnKeyDown = null;
  let newOnMouseDown = null;

  if (onClick) {
    newOnClick = (e) => {
      taggingInfo = {
        ...taggingInfo,
        eventType: "click",
      };
      wrapperFunction({ taggingInfo, action2Execute: onClick });
    };
    newOnKeyDown = (e) => {
      if (properKeyChecker(e)) {
        taggingInfo = {
          ...taggingInfo,
          eventType: "enter",
        };
        wrapperFunction({ taggingInfo, action2Execute: onClick });
      }
    };
    newOnMouseDown = (e) => {
      if (properMouseButtonChecker(e)) {
        taggingInfo = {
          ...taggingInfo,
          eventType: "clickMouseWheel",
        };
        wrapperFunction({ taggingInfo, action2Execute: onClick });
      }
    };
  } else {
    newOnClick = (e) => {
      taggingInfo = {
        ...taggingInfo,
        eventType: "click",
      };
      wrapperFunction({ taggingInfo });
    };
    newOnKeyDown = (e) => {
      if (properKeyChecker(e)) {
        taggingInfo = {
          ...taggingInfo,
          eventType: "enter",
        };
        wrapperFunction({ taggingInfo });
      }
    };
    newOnMouseDown = (e) => {
      if (properMouseButtonChecker(e)) {
        taggingInfo = {
          ...taggingInfo,
          eventType: "clickMouseWheel",
        };
        wrapperFunction({ taggingInfo });
      }
    };
  }
  if (disabled) {
    newOnClick = () => {
      return false;
    };
    newOnKeyDown = () => {
      return false;
    };
    newOnMouseDown = () => {
      return false;
    };
  }
  return {
    newOnClick,
    newOnKeyDown,
    newOnMouseDown,
  };
};

export const extendsAccesibility = ({
  cmpTitle,
  cmpAriaLabel,
  cmpScreenRedable,
  taggingInfo,
}) => {
  let title = cmpTitle;
  let ariaLabel = cmpAriaLabel;
  const ariaHidden = !cmpScreenRedable;

  if (!title && taggingInfo && taggingInfo.componentContent) {
    title = taggingInfo.componentContent;
  }

  if (!ariaLabel && taggingInfo && taggingInfo.componentContent) {
    ariaLabel = taggingInfo.componentContent;
  }

  return {
    title,
    ariaLabel,
    ariaHidden,
  };
};
