
import {  createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers/index'
import rootSaga from './saga/index';

const bindMiddleware = (middleware) => {
  const currentEnv = `${process.env.NEXT_PUBLIC_ENV_NAME}`;
  const { composeWithDevTools } = require('@redux-devtools/extension');
  if (currentEnv !== 'prod') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer(),
    bindMiddleware([sagaMiddleware])
  );
  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
}

export const store = makeStore();


export default store;