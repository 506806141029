/* eslint-disable prettier/prettier */
import React from "react";
import Text from "@gerenciatd/next13.components.text";

const Header5Title = (props) => {
  const { title, theme } = props;
  if (!theme) {
    return null;
  }
  let fontType, size, color;
  switch (theme) {
    case "areaprivada":
      size = "title04";
      color = "white";
      fontType = "font-regular";
      break;
    case "detalle":
      size = "title04";
      color = "dark-blue";
      fontType = "font-bold";
      break;

    default:
      size = "title03";
      color = "dark-blue";
      fontType = "font-bold";
  }
  return (
    <div className="header5-title">
      <div className={theme}>
        <div className="container">
          <Text text={title} fontType={fontType} size={size} color={color} />
        </div>
      </div>
    </div>
  );
};
export default Header5Title;
