import getCookie from '@gerenciatd/next13.utils.common.get-cookie'
import secureStorage from '@gerenciatd/next13.utils.secure.local-storage';
const secureStorageLib = new secureStorage();


//C2 => analitica
export const isOneTrustConsentsAnalitycsChecked = () => {
    const cookieValue = getCookie('OptanonConsent');
    return cookieValue.indexOf("C0002:1") > 0;
  }

export function persistedInfo() {
    let geo = {};

    const persistedInfo = secureStorageLib.getItem('ts') || {};
    if (persistedInfo && persistedInfo.geo) {
        geo = {
            cp: persistedInfo.geo.postalCode || '',
            zone: persistedInfo.geo.zone || '',
            municipio: persistedInfo.geo.municipio || '',
            businessZones: persistedInfo.geo.businessZones || '',
        }
    } else {
        if (isOneTrustConsentsAnalitycsChecked()){
            geo = null;
        } else {
            geo = {
                cp: "28030",
                zone: "ZL",
                municipio: 'Madrid',
                businessZones: [],
            }
        }
    }
    

    return { geo }
}