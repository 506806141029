import getHeaderSections from "@gerenciatd/next13.normalizers.components-header.sections";
import getBreadcrumbs from "@gerenciatd/next13.normalizers.components-header.breadcrumbs";
import { camelCase } from "../utils";

export function normalizerData(data, context, url) {
  const [jsonHeader, breadcrumb] = data;
  // URL ATENCION AL CLIENTE
  if (window.location.origin === 'https://atencionalcliente.movistar.es') url = window.location.origin;
  // URL FROM URLPARAMS
  if (context?.url) url = context.url;

  // VALIDATE REGEX TRUE
  let arrayFromUrl = url.split('/');
  let validateUrlRegex = "";
  let regexp = false;
  let breadcrumbsJsonWithRegex;
  let breadcrumbsJsonCustom;
  let breadcrumbsJson;
  arrayFromUrl.shift();
  arrayFromUrl.forEach(element => {
    validateUrlRegex = `${validateUrlRegex}/${element}`;
    breadcrumbsJson = getBreadcrumbs(breadcrumb, validateUrlRegex);
    if (breadcrumbsJson && breadcrumbsJson.breadcrumbs) {
      breadcrumbsJsonCustom = breadcrumbsJson;
    }
    if (breadcrumbsJson && breadcrumbsJson.breadcrumbs && breadcrumbsJson.breadcrumbs.regexp) {
      regexp = true;
      breadcrumbsJsonWithRegex = breadcrumbsJson;
    }
  });
  // BREADCRUMBS
  if (regexp && breadcrumbsJsonWithRegex) {
    breadcrumbsJson = breadcrumbsJsonWithRegex;
  } else {
    breadcrumbsJson = getBreadcrumbs(breadcrumb, url);
  }
  // CUSTOM BREADCRUMBS
  if (breadcrumbsJson && !breadcrumbsJson.breadcrumbs && 
    breadcrumbsJsonCustom && breadcrumbsJsonCustom.breadcrumbs &&
     breadcrumbsJsonCustom.breadcrumbs.breadCrumbs) {
    
    breadcrumbsJson = breadcrumbsJsonCustom;
    let objBreadCrumb = {};
    let breadCrumbsArray = Object.entries(breadcrumbsJsonCustom.breadcrumbs.breadCrumbs);
    breadCrumbsArray.forEach(([key, value]) => {
      objBreadCrumb[key] = value;
    });
    if (Object.values(objBreadCrumb).length > 2) {
        breadcrumbsJsonCustom.breadcrumbs.breadCrumbs = objBreadCrumb;
      } else {
        let objKey = breadcrumbsJsonCustom.breadcrumbs?.['title'];
        let objValue = breadcrumbsJsonCustom.breadcrumbs?.['url'];
        objBreadCrumb[objKey] = objValue;
        breadcrumbsJsonCustom.breadcrumbs.breadCrumbs = objBreadCrumb;
      }
      let fixTitle = arrayFromUrl.slice(-1)[0];
      if (arrayFromUrl.slice(-1)[0].indexOf("-") != -1) {
        fixTitle = camelCase(fixTitle).replaceAll('-', ' ');
        breadcrumbsJson.breadcrumbs.title = fixTitle;
      } else {
        breadcrumbsJson.breadcrumbs.title = camelCase(fixTitle);
      }
      breadcrumbsJson.breadcrumbs['title-theme'] = "detalle";
      breadcrumbsJson.pills = null;
  }
  // HEADERINFO
  let getHeaderSectionInfo = getHeaderSections(jsonHeader, breadcrumbsJson);
  const { breadcrumbs, pills } = breadcrumbsJson;
  // UNIFY HEADERINFO + BREADCRUMBS
  if (breadcrumbs) getHeaderSectionInfo = { ...getHeaderSectionInfo, breadcrumbs };
  if (pills) getHeaderSectionInfo = { ...getHeaderSectionInfo, pills };
  // ADD VALUES OF THE CONTEXT AND URLPARAMS
  const { headerName, headerType, forcedType, utm_source, utm_medium, environment, links } = context;
  getHeaderSectionInfo = {
    ...getHeaderSectionInfo, headerName, headerType,
    forcedType, utm_source, utm_medium, environment, links
  };
  // DELETE UNDEFINED VALUES
  Object.keys(getHeaderSectionInfo).forEach(key =>
    !getHeaderSectionInfo[key] && delete getHeaderSectionInfo[key]);
  return getHeaderSectionInfo;
}