import React, { Component } from "react";
import { connect } from "react-redux";
import getDeviceKindDependsOnViewPort from "@gerenciatd/next13.utils.common.get-device-kind-depends-on-viewport";
import { actions as modalActions } from "@gerenciatd/next13.reducers.modals-reducer";
import Icon from "@gerenciatd/next13.glyph.buscar-m";
import CustomLink from "@/components/custom-link-react";

const SupportAndSearch = React.lazy(() => import("@/components/header/modals/support-search"))
const SearchInput = React.lazy(() =>
  import("@/components/header/components/searcher-react/search-input")
);

class Searcher extends Component {
  constructor() {
    super();
    this.state = {
      device: null,
    };
  }

  setDevice = () => {
    const device = getDeviceKindDependsOnViewPort();
    this.setState({ device });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { device } = this.state;
    const { device: nextDevice } = nextState;
    const shouldRender = device !== nextDevice;
    if (device && shouldRender) {
      const { addModal } = this.props;
      addModal({
        id: "",
        type: "MODAL",
      });
    }
    return shouldRender;
  }

  componentDidMount() {
    this.setDevice();
    window.addEventListener("resize", this.setDevice);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDevice);
  }
  onCloseHandler = () => {
    const { addModal } = this.props;
    addModal({
      id: "closeAll",
    });
  };

  openSearchModal = () => {
    const { addModal } = this.props;
    addModal({
      id: "searcher",
      Element2Show: SupportAndSearch,
      modalProps: {
        contentPosition: "center--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeIn",
        closeAllOnClickOutside: true,
        contentInsideContainer: true,
        outSceneEffect: "fadeOut",
        closeByClickingOutside: true,
        onCloseHandler: this.onCloseHandler,
      },
      elementProps: {
        onCloseHandler: this.onCloseHandler,
        visibleInput: true,
      },
    });
  };
  render() {
    const { device } = this.state;

    if (!device) {
      return null;
    }

    if (device != "MOBILE") {
      return <SearchInput {...this.props} className="desktop" />;
    }

    return (
      <CustomLink
      onClick={this.openSearchModal}
      taggingInfo={{
        componentName: "header",
        componentContent: "openSearcher",
        componentVariation: "all",
        componentPerso: "",
        componentProduct: "",
      }}
      cyData="Header icon search"
    >
      <Icon color="grey6" id="buscar" kind="m" width={22} />
    </CustomLink>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(null, mapDispatchToProps)(Searcher);