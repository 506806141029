import { all, call, put, select, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { actions as headerActions } from '@gerenciatd/next13.reducers.header-reducer';
import getDeviceKindDependsOnViewPort from '@gerenciatd/next13.utils.common.get-device-kind-depends-on-viewport';


const filePath = 'header-saga/index.js'
let DEVICE_MAX_RESULTS = 8;
let PAGES_MAX_RESULTS = 3;
let SERVICES_MAX_RESULTS = 4;


const contextFromStore = (state) => state.main.context;

// SEARCH ENGINE

const searchEngines = {
    pages: null,
    asistente: null,
}

const normalizerQuery = (queryStr) => {
    let res = queryStr.toLowerCase();
    res = res.replace(/á/g, 'a');
    res = res.replace(/é/g, 'e');
    res = res.replace(/í/g, 'i');
    res = res.replace(/ó/g, 'o');
    res = res.replace(/ú/g, 'u');
    return res;
}

let searchApi = null;
let Search = null;

function* createSearchEngine(
    info,
    props = {
        fieldNamesOrIndexFunction: [
            'name',
            'path',
            'brand',
            'storage',
            'category',
            'color',
            'normalizedName',
            'normalizedFilter',
            'filter',
            'allProductsName'
        ],
        resourceName: 'pages',
    }
) {
    try {
        return eventChannel((emitter) => {
            const iv = 0;
            const { resourceName } = props;
            searchEngines[resourceName] = new searchApi.default({ Search });

            searchEngines[resourceName].indexResource({
                ...props,
                resources: info,
            });

            searchEngines[resourceName].subscribe(
                (data) => {
                    emitter(data);
                },
                (error) => { console.log(error) },
            );

            // The subscriber must return an unsubscribe function, but it will never happen
            return () => {
                clearInterval(iv);
            };
        });
    } catch (error) {
        console.log(error)
    }
}

export function* initAvSearchApiListener(avList) {
    try {
        const chan = yield call(createSearchEngine, avList, {
            fieldNamesOrIndexFunction: ['normalizedName'],
            resourceName: 'asistente'
        });
        while (true) {
            const info = yield take(chan);
            const { result } = info;

            const querySuggestedInputs = result.slice(0, 3).map(item => {
                const newItem = {
                    label: item,
                    kind: 'av'
                }
                return newItem
            })

            yield put(headerActions.setSuggestedInputs({
                querySuggestedInputs: [...querySuggestedInputs]
            }))
            // hacemos la logica de suggested
            // yield call(getSuggestedInputs, query, categoriesMatched);
        }
    } catch (error) {
        console.error(error)
    }
}

export function* initPagesSearchApiListener(pagesList) {
    try {
        const chan = yield call(createSearchEngine, pagesList);
        while (true) {
            const info = yield take(chan);
            const { result, text: query } = info;
            const pagesList = yield select(state => state.header.pagesList);
            const categoryOptions = yield select(state => state.header.categoryOptions);
            const categoriesMatched = [];
            const mappedResults = {
                devices: [],
                regular: [],
                services: [],
            }

            const device = getDeviceKindDependsOnViewPort();
            if (device == 'MOBILE') {
                DEVICE_MAX_RESULTS = 6;
                PAGES_MAX_RESULTS = 2;
                SERVICES_MAX_RESULTS = 2;
            }

            // for clasico para limitar la busqueda
            let cndStop = false;
            for (let i = 0; (!cndStop && i < result.length); i++) {
                const itemFounded = result[i];
                const item = {
                    key: itemFounded,
                    pathName: itemFounded,
                    ...pagesList[itemFounded],
                };
                const isDevice = item && item.brand;
                const isService = item && item.products

                let cndMaxDevice = mappedResults.devices.length < DEVICE_MAX_RESULTS;
                let cndMaxService = mappedResults.services.length < SERVICES_MAX_RESULTS;
                const cndMaxPages = mappedResults.regular.length < PAGES_MAX_RESULTS;

                if (isDevice) {
                    // almacenamos categoria encontrada
                    const deviceCategories = (item.category && item.category) ? item.category?.split(',') || [] : [];

                    if (deviceCategories && Array.isArray(deviceCategories)) {
                        deviceCategories.forEach(currentCategory => {
                            const noAlreadyPushed = categoriesMatched.find(category => category && (category.id === currentCategory))
                            if (!noAlreadyPushed) {
                                const existCategory = categoryOptions && categoryOptions[currentCategory]
                                if (existCategory) {
                                    categoriesMatched.push(existCategory);
                                }
                            }
                        })
                    }
                    // creamos elemento por
                    if (cndMaxDevice && item.minPrice) {
                        const currentItem = {
                            ...item,
                            key: `${item.key}.min`,
                            'prePrice': 'Por',
                            'unity': "€",
                            'amount': item.minPrice,
                        }
                        mappedResults.devices.push(currentItem);
                        cndMaxDevice = mappedResults.devices.length < DEVICE_MAX_RESULTS;
                    }
                } else if (isService) {
                    if (item && item.products) {
                        item.products.forEach(product => {
                            if (cndMaxService) {
                                const smallItem = {
                                    ...item,
                                    ...product,
                                }
                                smallItem.amount = smallItem.price;
                                delete smallItem.price;
                                delete smallItem.products;
                                const exist = mappedResults.services.findIndex(item => item.name === smallItem.name);
                                if (exist === -1) {
                                    mappedResults.services.push(smallItem);
                                    cndMaxService = mappedResults.services.length < SERVICES_MAX_RESULTS;
                                }
                            }
                        })
                    }
                } else if (cndMaxPages) {
                    mappedResults.regular.push(item);
                }
                // analizo si termino
                if (!cndMaxDevice && !cndMaxService && !cndMaxPages) {
                    cndStop = true;
                    break;
                }

            }
            // ordena las estructuras por precio
            mappedResults.devices.sort((itemA, itemB) => {
                const alphabetically = itemA.name - itemB.name;
                if (alphabetically !== 0) {
                    return alphabetically
                }
                return parseInt(itemA.amount, 10) - parseInt(itemB.amount, 10)
            })
            mappedResults.services.sort((itemA, itemB) => {
                return parseInt(itemA.amount, 10) - parseInt(itemB.amount, 10)
            })

            // hariamos los put de results
            yield put(headerActions.setRelatedPages({
                pagesList: mappedResults,
                categoriesMatched,
                inputQuery: query,
            }));
        }
    } catch (error) {
        console.error(error)
    }
}

const makeUpCustomObjectsArray = (object) => {
    const res = []
    Object.keys(object).forEach(key => {
        const item = object[key];
        const newItem = {
            path: key,
            id: key,
            normalizedName: item.name ? normalizerQuery(item.name) : '',
            normalizedFilter: item.filter ? normalizerQuery(item.filter) : '',
            ...item,
        }
        if (item && item.category && item.category.indexOf('novedades') !== -1) {
            res.unshift(newItem);
        } else {
            res.push(newItem)
        }
    })
    return res;
}

const normalizeCategoryOptions = (categoryOptions) => {
    let res = {};

    Object.keys(categoryOptions).forEach(keyName => {
        const currentItem = categoryOptions[keyName];
        const { title: label, url: pathName } = currentItem;
        const newItem = {
            key: keyName,
            id: keyName,
            label,
            pathName,
            kind: 'page'
        }
        res[keyName] = newItem;
    })

    return res;
}

let allInfo = null;
export function* getSearcherInfo() {
    // Trocear trycatch por peti
    try {
        let infoSearcher = null;
        // vemos si ya tenemos la info
        let pagesList = null;
        let categoryOptions = null;
        let pagesListMapped = null;
        let suggestedInputs = null;
        infoSearcher = {};
        // buscamos toda la info que necesitamos
        const context = yield select(contextFromStore);
        const { pageSegment: segment } = context;
        if (!allInfo) {
            const infoNeeded = yield all([
                call(() => import('js-worker-search')),
                call(() => import('@/utils/promises/search-api')),
                call(() => import('@/utils/promises/getAllSearchInfoPromise'))
            ]);
            Search = infoNeeded[0].default;
            searchApi = infoNeeded[1];
            let getAllSearchInfo = infoNeeded[2];
            getAllSearchInfo = getAllSearchInfo.default;
            allInfo = yield call(getAllSearchInfo, { segment, context });

            // ME QUEDO CON LOS DATOS
            if (allInfo && allInfo.av) {
                suggestedInputs = allInfo.av.map((item, index) => {
                    return {
                        key: index,
                        id: item,
                        normalizedName: normalizerQuery(item),
                    }
                })
            }
            if (allInfo && allInfo.categoryOptions) {
                categoryOptions = normalizeCategoryOptions(allInfo.categoryOptions);
            }
            if (allInfo && allInfo.paths) {
                pagesListMapped = allInfo.paths;
                pagesList = makeUpCustomObjectsArray(allInfo.paths);
            }
            // guardamos en session
            infoSearcher.pagesList = pagesListMapped;
            infoSearcher.suggestedInputs = suggestedInputs;
            yield put(headerActions.setSearcherInfo({
                pagesList: infoSearcher.pagesList,
                suggestedInputs,
                categoryOptions,
            }));
            // inicializamos apis buscador de paginas => yield all
            // inicializo apis buscador de av
            yield all([
                call(initAvSearchApiListener, suggestedInputs),
                call(initPagesSearchApiListener, pagesList),
            ])

        }

    } catch (error) {
        console.log(error);
    }
}
// performn search
export function* getRelatedPages({ query }) {
    try {
        const normalizedQuery = normalizerQuery(query);
        if (searchEngines && searchEngines['pages'] && searchEngines['asistente']) {
            searchEngines['pages'].performSearch('pages', normalizedQuery);
            searchEngines['asistente'].performSearch('asistente', normalizedQuery);
        }
    } catch (error) {
        console.log(error);
    }
}