/* eslint-disable prettier/prettier */
import React from "react";
const BurgerMenu = React.lazy(() => import("./components/burger"));
const IconsMenu = React.lazy(() => import("./components/icons"));
const DefaultMenu = React.lazy(() => import("./components/default"));
const CPMenu = React.lazy(() => import("./components/cp"));
/*
const IconLabelMenu = dynamic(() => import('./layouts/icon-label'));
const Default = dynamic(() => import('./layouts/default'));
*/
const MENU_LAYOUTS = {
  BURGER: "burguer",
  ICONS: "icons",
  DEFAULT: "default",
  CP: "cp",
};

const Menu = (props) => {
  const { layout } = props;
  switch (layout) {
    case MENU_LAYOUTS.CP: return <CPMenu {...props} />;
    case MENU_LAYOUTS.BURGER: return <BurgerMenu {...props} />;
    case MENU_LAYOUTS.DEFAULT: return <DefaultMenu {...props}/>;
    case MENU_LAYOUTS.ICONS: return <IconsMenu {...props} />;
    default: return null;
  }
};
export default Menu;
