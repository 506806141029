import { combineReducers } from 'redux';
import mainReducer from '@gerenciatd/next13.reducers.main-reducer';
import modalsReducer from '@gerenciatd/next13.reducers.modals-reducer';
import headerInfoReducer from './header-info-reducer';
import headerReducer from '@gerenciatd/next13.reducers.header-reducer';
const REDUCERS_LIST = {
  main: mainReducer,
  modals: modalsReducer,
  header: headerReducer,
  headerInfo: headerInfoReducer,
}

const rootReducers = () =>
  combineReducers({
    ...REDUCERS_LIST,
  });

export default rootReducers;
