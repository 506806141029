import { put, call, select, all } from "redux-saga/effects";
import getStaticContent from "@gerenciatd/next13.adabyron.misc.get-static-content";
import getUrlParams from "@gerenciatd/next13.utils.common.get-url-params";
import { actions as headerActions } from "@/reducers/header-info-reducer";
import { actions as mainActions } from '@gerenciatd/next13.reducers.main-reducer';
import secureLocalStorage from '@gerenciatd/next13.utils.secure.local-storage'
import { normalizerData } from "@/utils/normalizer";
import { checkUrlBreadcrumbs } from "@/utils/utils";

function loadUrlParams(search) {
    if (search && window?.location?.href) {
        const urlParams = getUrlParams({
            groupByObject: true,
            allowedParams: null,
            currentUrl: window.location.href,
        });

        return urlParams;
    }
}


const NEW_APP_ACCOUNT = {
    "inicio": {
       "text": "Mis productos",
       "link": "/area-cliente/mis-productos"
    },
    "facturas": {
      "text": "Facturas",
      "link":"https://www.movistar.es/cliente/areaprivada/#/facturas"
   },
    "gestiones": {
      "text": "Gestiones",
      "link":"/area-cliente/gestiones"
    },
    "consumos": {
      "text": "Consumos",
      "link":"/area-cliente/mis-productos/consumos"
    },
    "perfil": {
      "text": "Mi perfil",
      "link": "/area-cliente/mi-perfil"
   },
    "atencion-cliente": {
       "text": "Atención al cliente",
       "link":"https://www.movistar.es/atencion-cliente/ayuda"
    }
  }
  
export const getMarkNewAP = (persistedInfo) => {
    const markList = persistedInfo && persistedInfo.userPq && persistedInfo.userPq.ml && persistedInfo.userPq.ml;
    let nAP = false;
    if (markList && Array.isArray(markList) && markList.length > 0 && markList.includes('nAP')) {
        nAP = true
    }
    return nAP;
}

export function* loadJsonHeader(location) {
    try {
        const secureLocal = new secureLocalStorage();
        const persistedInfo = secureLocal.getItem('ts')
        const contextFromReducerSelector = state => state.main.context;
        let context = yield select(contextFromReducerSelector);
        const promisesArray = [
            call(getStaticContent, { context, resourcePath: "/static/json/header/header_plan_r.json", build: false }),
            call(getStaticContent, { context, resourcePath: "/static/json/header/breadcrumbs.json", build: false })
        ];

        const data = yield all(promisesArray);

        const mark = getMarkNewAP(persistedInfo)

        let { pathname, search, hash } = location.location;
        // check cuando hash tenga search
        if (hash.indexOf("?") != -1) {
            const hashWithoutSearch = hash.substr(0, hash.indexOf('?'));
            const searchFromHash = hash.substring(hashWithoutSearch.length);
            hash = hashWithoutSearch;
            search = searchFromHash;
        }
        if (search) {
            const paramsFromUrl = loadUrlParams(search);
            if (paramsFromUrl && Object.keys(paramsFromUrl).length !== 0) {
                if (paramsFromUrl?.url) paramsFromUrl.url = checkUrlBreadcrumbs(paramsFromUrl.url, hash);
                context = { ...context, ...paramsFromUrl };
            }
        }
        let urlBreadcrumbs = !pathname ? '/' : pathname;
        urlBreadcrumbs = checkUrlBreadcrumbs(pathname, hash);
        const dataFromNormalicer = normalizerData(data, context, urlBreadcrumbs);
        if (mark) {
            let accountValue = {}
            const accountsObject = Object.keys(NEW_APP_ACCOUNT);
            accountsObject.forEach((key, index) => {
                accountValue[index] = NEW_APP_ACCOUNT[key]
            })
            dataFromNormalicer.account = accountValue;
        }
        yield put(headerActions.setLoadInfo(dataFromNormalicer));

    } catch (error) {
        console.log(error);
    }
}

export function* checkCPAndSetZones({data}) {
    try{
        const {cp, needBussinesZone } = data;
        let module = yield call(() => import('@gerenciatd/next13.adabyron.geo.get-cp-zone'));
        module = module.default;
        
        const lastCP = yield select(state => state.main.cp);
        const context = yield select(state => state.main.context)

        // need get again bussinessZones, cuando montamos el modal con datos ya persistidos y no tenmoes la lista de municipios
        if (needBussinesZone) {
            yield put(mainActions.setCheckedCPByApi(false))
            let response = yield call(module, { cp, context });
            const { businessZones } = response;
            const lastZone = yield select(state => state.main.zone);
            const lastMunicipio = yield select(state => state.main.municipio);

            response = {
                cp:lastCP,
                zone: lastZone,
                municipio: lastMunicipio,
                businessZones,
            }
            yield put(mainActions.setCheckedCPByApi(true));
            yield put(mainActions.setCpParams(response));
        }

        // regular behaviour
        if (!needBussinesZone && lastCP !== cp) {
            yield put(mainActions.setCheckedCPByApi(false))
            let response = yield call(module, { cp, context });
            let zone = (response && response.zone) || 'ZL';
            let municipio = null;
            // getting first result and using its zone
            if (response && response.businessZones && Array.isArray(response.businessZones) && response.businessZones.length > 0){
                const { businessZoneName } = response.businessZones[0];
                zone = businessZoneName;
                municipio = response.businessZones[0]
            }
            
            response = {
                ...response,
                cp: response.postalCode,
                zone,
                municipio
            }
            delete response.postalCode;
            yield put(mainActions.setCpParams(response));
            yield put(mainActions.setCheckedCPByApi(true))
        }



    } catch(err) {
        yield put(mainActions.setCheckedCPByApi(false))
    }
}