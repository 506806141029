import React, { Component } from "react";
import CustomLink from "@/components/custom-link-react";
import Text from "@gerenciatd/next13.components.text";
import Icon from "@gerenciatd/next13.glyph.usuario-m";
import IconClose from "@gerenciatd/next13.glyph.cerrar-m";
import { connect } from "react-redux";
import { actions as modalActions } from "@gerenciatd/next13.reducers.modals-reducer";
import getDeviceKindDependsOnViewPort from "@gerenciatd/next13.utils.common.get-device-kind-depends-on-viewport";

const ModalUser = React.lazy(() => import("@/components/header/modals/user"));

class UserPill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
    };
  }

  setDevice = () => {
    const device = getDeviceKindDependsOnViewPort();
    this.setState({ device });
  };

  componentDidMount() {
    this.setDevice();
    window.addEventListener("resize", this.setDevice);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setDevice);
  }

  openModal = () => {
    const { addModal, items, segmentMenu, options, userName, userType } = this.props;
    const { device } = this.state;
    const fadeIn = device === "MOBILE" ? "fadeInTop" : "fadeInTop";
    const fadeOut = device === "MOBILE" ? "fadeOutTop" : "fadeOutTop";
    addModal({
      id: "account",
      Element2Show: ModalUser,
      modalProps: {
        contentPosition: "right--top",
        opacityLayoutColor: "blur",
        inSceneEffect: fadeIn,
        contentInsideContainer: true,
        outSceneEffect: fadeOut,
        closeByClickingOutside: true,
        onCloseHandler: this.onCloseHandler,
      },
      elementProps: {
        items: { ...items },
        userName: userName,
        userType: userType,
      },
    });
    this.setState({
      modalOpened: true,
    });
  };

  onCloseHandler = () => {
    const { modals, addModal } = this.props;
    if (modals.length > 0) {
      addModal({
        id: "closeAll",
      });
    }
  };

  closeModal = () => {
    const { addModal } = this.props;
    addModal({
      id: "closeAll",
    });
    this.onCloseHandler();
  };

  render() {
    const { userName } = this.props;

    return (
      <div className="user-pill">
        <CustomLink
          onClick={this.openModal}
          title="Mi cuenta"
          ariaLabel="Mi cuenta"
          className="button"
          taggingInfo={{
            componentName: "header",
            componentContent: "openAccount",
            componentVariation: "all",
            componentPerso: "",
            componentProduct: "",
          }}
        >
          <div className="user-icon">
            <Icon id="Usuario" color="white" width="20px" kind="M" />
          </div>
        </CustomLink>
        <div className="user-content">
          <div className="user-name">{userName}</div>

          <div className="user-menu">
            <div className="item">
              <CustomLink
                onClick={this.openModal}
                title="Mi cuenta"
                ariaLabel="Mi cuenta"
                taggingInfo={{
                  componentName: "header",
                  componentContent: "user-pill",
                  componentVariation: "all",
                  componentPerso: "",
                  componentProduct: "",
                }}
              >
                <Text
                  text="Mi cuenta"
                  fontType="font-regular"
                  size="caption03"
                  color="blue"
                  display="block"
                />
              </CustomLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  modals: state.modals.modalHistory,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPill);
