import React, { Component } from 'react';
import Sticky from './components/sticky';
import Header1Main from './containers/header1-main';
import Header2Nav from './containers/header2-nav';
import Header3Breadcrumb from './containers/header3-breadcrumb';
import Header4Section from './containers/header4-section';
import Header5Title from './containers/header5-title';


class Header extends Component {
    constructor(props){
        super(props)
    }

    componentDidUpdate(){
        try {
              const appElement = document.getElementById('header-root-plan-r');
              const referenceElement = document.getElementById('header-app-rr')
              if (referenceElement && referenceElement.offsetHeight){
                  appElement.style.minHeight=`${referenceElement.offsetHeight}px`;
              }
          } catch(err){
            
          }
    }

    componentDidMount(){
        try {
              const appElement = document.getElementById('header-root-plan-r');
              const referenceElement = document.getElementById('header-app-rr')
              if (referenceElement && referenceElement.offsetHeight){
                  appElement.style.minHeight=`${referenceElement.offsetHeight}px`;
              }
          } catch(err){
            
          }
    }
    
    render(){
        const {
            cp,
            municipio,
            municipiosPosibles,
            url,
            mainOptions,
            navSections,
            segmentMenu,
            burgerSections,
            burgerOptions,
            account,
            pills,
            breadcrumbs,
            context,
            headerType,
            headerName
        } = this.props;
        const { userType, userName } = context;
        if (mainOptions) {
            return (
                <div className='header'>
                    <Sticky>
                        <Header1Main
                            mainOptions={mainOptions}
                            navSections={navSections}
                            segmentMenu={segmentMenu}
                            burgerSections={burgerSections}
                            burgerOptions={burgerOptions}
                            accountSections={account}
                            logged={userType && userName ? userType : headerType}
                            userName={userName ? userName : headerName}
                            breadcrumb={breadcrumbs}
                        />
                    </Sticky>
                    <Header2Nav
                        navSections={navSections}
                        segmentMenu={segmentMenu}
                        breadcrumb={breadcrumbs}
                        logged={userType && userName ? userType : headerType}
                        cp={cp}
                        municipio={municipio}
                        municipiosPosibles={municipiosPosibles}
                    />
                    <Header3Breadcrumb breadcrumb={breadcrumbs} />
                    <Header4Section
                        pills={pills}
                    />
                    <Header5Title
                        theme={breadcrumbs?.["title-theme"] ? breadcrumbs["title-theme"] : null}
                        title={breadcrumbs?.["title"] ? breadcrumbs["title"] : null}
                    />
                </div>
            )
        } return null;
    }
}


export default Header