import React from "react";
import CustomLink from "@/components/custom-link-react";
import Text from "@gerenciatd/next13.components.text";

const Breadcrumb = (props) => {
  const { breadcrumb } = props;
  if (!breadcrumb) {
    return null;
  }
  const items = Object.entries(breadcrumb["breadCrumbs"]);
  let i = 0;
  return (
    <nav>
      <ul className="breadcrumb" data-cy="Header breadcrumb">
        {items.map(([key, value]) => {
          const link = value ? value : "#";
          const text = key;
          return (
            <li key={`item-${i++}`} className="breadcrumb-item">
              <CustomLink
                internal={false}
                href={link}
                onClick={() => { window.location.href = link }}
                title=""
                ariaLabel=""
                taggingInfo={{
                  componentName: "header",
                  componentContent: text,
                  componentVariation: "breadcrumb",
                  componentPerso: "",
                  componentProduct: "",
                }}
              >
                <Text
                  text={text}
                  fontType="font-regular"
                  size="caption03"
                  color="grey6"
                  display="inline"
                />
              </CustomLink>
            </li>
          );
        })}

        <li className="breadcrumb-item active">
          <Text
            text={breadcrumb.title}
            fontType="font-regular"
            size="caption03"
            color="grey5"
            display="inline"
          />
        </li>
      </ul>
    </nav>
  );
};
export default Breadcrumb;
