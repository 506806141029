export async function apiCall(method, url) {
  return fetch(url, {
    method,
  }).then(response => response.json());
}

export const hanTranscurridoSeisMeses = (desde, hasta) => {
  let res = false;
  if (desde && hasta) {
    const seisMesesEnMilisegundos = 6 * 30 * 24 * 60 * 60 * 1000; // Asumiendo meses de 30 días
    res = hasta - desde >= seisMesesEnMilisegundos;
  }
  return res;
}

export function checkUrlBreadcrumbs(pathname, hash) {
  let urlBreadcrumbs = "";
  if (pathname !== '/') {
    let checkPathName = pathname;
    if (checkPathName[checkPathName.length - 1] == '/') {
      checkPathName = checkPathName.substring(0, checkPathName.length - 1);
      urlBreadcrumbs = checkPathName;
    } else {
      urlBreadcrumbs = pathname;
    }
  }
  if (hash) {
    if (hash.indexOf("#/") != -1) {
      if ((hash.match(/#/g) || []).length > 1) {
        let newHash = hash.split('#', 2);
        hash = newHash.join('#');
      }
      urlBreadcrumbs = `${pathname}${hash}`;
      let checkPathNameAndHash = urlBreadcrumbs;
      if (checkPathNameAndHash[checkPathNameAndHash.length - 1] == '/') {
        checkPathNameAndHash = checkPathNameAndHash.substring(0, checkPathNameAndHash.length - 1);
        urlBreadcrumbs = checkPathNameAndHash;
      }
    }
  }
  return urlBreadcrumbs;
}

export function mustBeShownHeader(headerInfo) {
  let res = true;

  if (!headerInfo) {
    res = false;
  }
  if (headerInfo) {
    const { forcedType, utm_source, utm_medium, environment, breadcrumbs } = headerInfo;
    if (forcedType && forcedType === 'none') res = false;
    if (utm_source && utm_medium) {
      if ((utm_source === 'novum' && utm_medium === 'app') || (utm_source === 'novum_telco' && utm_medium === 'app'))
        res = false;
    }
    if (environment === 'app') res = false;
    if (breadcrumbs && breadcrumbs["title-theme"] === "none") res = false;
    if (!res) {
      // desabilitamos skeleton
      try {
        const appElement = document.getElementById('header-root-plan-r');
        appElement.classList.add('disabled');
      } catch (err) {
        console.error(err);
      }
    }
  }


  return res;
}

export function mustBeShownHeaderLight(headerInfo) {
  if (headerInfo) {
    const { environment, breadcrumbs } = headerInfo;
    if (breadcrumbs && breadcrumbs["title-theme"] === "lightHeader") return true;
    if (environment === "BuyProcess") return true;
  }
}

export function WebviewBridgeReact(kind = 'title-and-breadcrum-visible') {

  let css2aply = '.header,.footer { display: none !important; }'
  switch (kind) {
    case 'title-and-breadcrum-visible': css2aply = '.header .Sticky, .header .header2-nav .menu, .footer { display: none !important; }'; break;
    case 'full':
    default: css2aply = '.header,.footer { display: none !important; }'; break;
  }
  // eliminoi el inico del rastro de migas
  css2aply = `${css2aply}.breacrumb-item-value-inicio{display: none !important}.breacrumb-item-value-inicio::after {display:none !important}`;
  var css = css2aply;
  let head = document.head || document.getElementsByTagName('head')[0];
  let style = document.createElement('style');
  head.appendChild(style);
  style.type = 'text/css';
    if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else { 
    style.appendChild(document.createTextNode(css));
  }
  
}

export function camelCase(str) {
  // Using replace method with regEx
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return word.toUpperCase();
  }).replace(/\s+/g, '');
}