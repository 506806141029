import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import isWebView from "@gerenciatd/next13.utils.common.is-webview";
import store from './store'
import App from "./App";
import "@/styles/globals.scss";
import "@/styles/icon.style.css";
import "@/styles/header.scss";
import "@/styles/searcher.scss";
import "@/styles/carousel.scss";
import "@/styles/streaming-platforms-section.scss";
import "@/styles/price.scss";
import "@/styles/card.scss";
import "@/styles/skeleton.scss";
import '@/styles/button-react.style.scss';
import '@/styles/custom-link-react.style.scss';
/* [STYLES] begins */
import '@/styles/price.style.scss';
import '@/styles/text.style.scss';
import '@/styles/grid.scss';
import '@/styles/colors.scss';
import '@/styles/fonts.scss';
import { WebviewBridgeReact } from "./utils/utils";
/* [STYLES] ends */

// cargamos iconos de aplicaciioens de terceros
/* checking incons styles  */
const ICONS_CSS_FILENAME = 'https://prod.cms.col.movistar.es/static/fonts/icon/icons.min.css?cache=040720221337';
const loadCssIcons = () => {
  let isAlreadyLoaded = false;
  const ss = document.styleSheets;
  for (let i = 0, max = ss.length; i < max; i += 1) {
    if (ss[i].href === ICONS_CSS_FILENAME) {
      isAlreadyLoaded = true;
      break;
    }
  }
  if (!isAlreadyLoaded) {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.async = 'async';
    link.defer = 'defer'
    link.href = ICONS_CSS_FILENAME;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
loadCssIcons();

const rootElement = document.getElementById("header-root-plan-r");
const root = createRoot(rootElement);

const disabledHeader = () => {
  try {
    const appElement = document.getElementById('header-root-plan-r');
    appElement.classList.add('disabled');
  } catch (err) {
    console.error(err);
  }
}


const isUnderWebView = window.location.href.indexOf('areaprivada/#/webviews') > -1;
if (isUnderWebView) disabledHeader();
const isUnderWebViewNovum = isWebView();
if (isUnderWebViewNovum) WebviewBridgeReact();
if (!isUnderWebView) {
  root.render(
    <Provider store={store}>
      <Suspense fallback={<div></div>}><App /></Suspense>
    </Provider>
  );
}
